<template>
  <div>
    <Title :title="!sample ? 'Upload Data' : `Update Data : ${sample.sample_date}`">
      <!-- <v-btn :color="status ? 'success' : 'success'" @click="setStatus">
        {{ status ? "Scan List" : "Create New Scan" }}
      </v-btn> -->
    </Title>
      <v-card class="mx-auto mt-5" tile>
        <v-list shaped>
          <v-list-item key="status-2">
            <v-row>
              <v-col class="mt-5">
                <h4>Farm:</h4>
                <v-select
                  v-model="currentfarm_id"
                  :items="farm_list"
                  item-value="id"
                  label="select Farm"
                  @change="changeFarm"
                  dense
                  solo
                  :class="(currentfarm_id ? 'pondlist_completed' : '' )"
                >
                  <template v-slot:item="{ item }">
                    <img :src="item.avatar" class="avatar"> {{item.name}} - {{item.active_pond_count}} Active
                  </template>
                  <template v-slot:selection="{ item }">
                    <img :src="item.avatar" class="avatar"> {{item.name}} - {{item.active_pond_count}} Active
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-if="currentfarm_id">
            <v-row>
              <v-col
                class="d-flex"
                :cols="isMobile ? 12 : 12"
              >
                <v-select
                  v-model="pond"
                  :items="pond_list"
                  item-value="id"
                  label="select Pond"
                  solo
                  :class="(pond ? 'pondlist_completed' : '' )"
                  class="mt-5"
                ></v-select>
                <div class="activeonly">Active Only</div>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item :class="isMobile ? 'block' : ''">
            <h4>Sample Date:</h4>
            <v-date-picker v-model="sample_date"></v-date-picker>
          </v-list-item>
          <v-list-item-group color="primary ">
            <!-- 1. New Input -->
            <template v-if="!sample">
              
            </template>
            <!-- 2. Update sample -->
            <template v-if="sample">
              <!-- only minnowtech side can change Cound and Biomass -->
              <template v-if="isMinnowtech">
              <v-list-item key="status-3" class="calculate">
                <v-list-item-icon class="mt-6 left_name">
                  <v-icon class="mr-3">mdi-laptop</v-icon>
                  Count:
                </v-list-item-icon>
                <v-list-item-content>
                  <v-text-field
                    label="Count"
                    v-model="count"
                    placeholder="Please input Count."
                    solo
                    class="mt-0"
                  >
                  </v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-list-item key="status-4" class="calculate">
                <v-list-item-icon class="mt-6 left_name">
                  <v-icon class="mr-3">mdi-laptop</v-icon>
                  Biomass:
                </v-list-item-icon>
                <v-list-item-content>
                  <v-text-field
                    label="Biomass"
                    v-model="biomass"
                    placeholder="Please input Biomass."
                    solo
                    class="mt-0"
                  >
                  </v-text-field>
                </v-list-item-content>
              </v-list-item>
              </template>
              <!-- END : only minnowtech side can change Cound and Biomass -->
            </template>
            <!-- END : 2. Update sample -->
            <v-list-item key="status-5" :class="isMobile ? 'block' : ''">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-circle</v-icon>
                Size (g):
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  ref="size"
                  label="size"
                  v-model="size"
                  placeholder="Please input Size."
                  solo
                  :error-messages="fieldErrors('size')"
                  @input="$v.size.$touch()"
                  @blur="$v.size.$touch()"
                  class="mt-0"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="status-6" :class="isMobile ? 'block' : ''">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-circle</v-icon>
                Temperature (°C):
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  ref="temperature"
                  v-model="temperature"
                  label="Temperature"
                  placeholder="Please input Temperature."
                  solo
                  :error-messages="fieldErrors('temperature')"
                  @input="$v.temperature.$touch()"
                  @blur="$v.temperature.$touch()"
                  class="mt-0"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="status-7" :class="isMobile ? 'block' : ''">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-circle</v-icon>
                Dissolved Oxygen<br/>(ml/L):
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  ref="oxygen"
                  label="Oxygen"
                  v-model="oxygen"
                  placeholder="Please input Oxygen."
                  solo
                  :error-messages="fieldErrors('oxygen')"
                  @input="$v.oxygen.$touch()"
                  @blur="$v.oxygen.$touch()"
                  class="mt-0"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="status-8" :class="isMobile ? 'block' : ''">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-circle</v-icon>
                Salinity (ppt):
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  ref="salinity"
                  label="Salinity"
                  v-model="salinity"
                  placeholder="Please input Salinity."
                  solo
                  :error-messages="fieldErrors('salinity')"
                  @input="$v.salinity.$touch()"
                  @blur="$v.salinity.$touch()"
                  class="mt-0"
                >
                </v-text-field>    
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="status-9" :class="isMobile ? 'block' : ''">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-order-bool-descending-variant</v-icon>
                Note:
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-textarea
                    v-model="note"
                    solo
                    auto-grow
                    name="Note"
                    label=""
                  ></v-textarea>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>     

          </v-list-item-group>

          <div class="d-flex justify">
            <v-btn
              color="success mt-5 mb-3 mx-auto"
              width="80%"
              @click="addScan()"
            >
              {{!sample ? "Upload Data" : "Update Data"}}
            </v-btn>
          </div>
        </v-list>
      </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import tokenAxios from "@/components/tokenAxios";
import Title from "@/components/Title";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { decimal } from "vuelidate/lib/validators";

export default {
  name: 'Scan',
  components: { Title },
  computed: {
    ...mapState(["isMobile", "user_info", "isMinnowtech"]),
    sample: function () {
      return this.$route.params.sample  // Sample ID parameter from the list
    }
  },
  mixins: [validationMixin],
  validations: {
    assigned: {},
    // pond: { required },
    // week: { required },
    size: { decimal },
    temperature: { decimal },
    oxygen: { decimal },
    salinity: { decimal },
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    currentfarm_id: null, // current farm
    farm_list: [],  // farm list
    pond_list: [],  // pond list
    pond: null,
    sample_date: null,
    count: null,
    biomass: null,
    aerator: false,
    feeder: false,
    maintenance: false,
    file: null,
    file_error: false,
    temperature: null,
    oxygen: null,
    salinity: null,
    note: '',
    size: null,
  }),
  created() {
    this.listFarms();
    // this.listPonds();
    this.$debug('$route.params', this.$route.params)
    // when Refresh browser send to pond list if user is farmer
    if( !this.sample && !this.isMinnowtech ) this.$router.push('/ponds')

    // Update sample/scan
    if(this.sample) {
      this.pond = this.sample.pond.id;
      this.sample_date = this.sample.sample_date;
      this.count = this.sample.count;
      this.biomass = this.sample.biomass;
      this.aerator = this.sample.aerator;
      this.feeder = this.sample.feeder;
      this.maintenance = this.sample.maintenance;
      this.size = this.sample.size;
      this.file = null;
      this.file_error = false;
      this.temperature = this.sample.temperature;
      this.oxygen = this.sample.oxygen;
      this.salinity = this.sample.salinity;
      this.note = this.sample.note;
    }
  },
  methods: {
    // Error Handling
    fieldErrors(field) {
      this.$debug('fieldErrors', field, this.$v, this.$v[field] )
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },    
    // List Farms
    async listFarms(page) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, this.user_info )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
          // const response = await tokenAxios().get(
            this.API_SERVER + `/farm/farm/?format=json` + (page ? `&page=${this.page}` : "")
          );
          this.$debug("get farm lists", response);
          if (response.status === 200 && response.data.length) {
            this.farm_list = [];            
            const newlist = response.data.map(o=> {
              if( !o.avatar ) {
                o.avatar = "https://farm-avatar.s3.amazonaws.com/minnowtech.jpg";
              }
              return o;
            })

            this.farm_list.push(...newlist);
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },
    changeFarm(o) {
      this.$debug("changeFarm", o, this.farm)
      this.pond = null;
      this.listPonds(o, 1)
      // Clear uploading files
      this.files = [];
    },
    // Pond List to select
    async listPonds(farm) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER )
          this.pondlist_completed = false;
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/pond/?status=1&no_page=1&format=json` + (farm ? `&farm=${farm}` : "")
          );
          this.$debug("get project lists", response);
          if (response.status === 200 && response.data.length) {
            this.pond_list = response.data;
            this.pond_list.map(o=>{
              this.$debug(`${o.pond_id} / ${o.date}`)
              o.text = `[${o.farm_name}] Pond ${o.pond_id} : ${o.date} (Pond Size: ${o.pond_size}, Shrimp Size: ${o.shrimp_size})`;
            });
            this.$debug( "this.pond_list", this.pond_list )
            this.pondlist_completed = true;
            // this.total = response.data.count;
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },
    listScan: async function (page) {
      try {
+        this.$store.commit("loadingStatus", 1);
        const response = await tokenAxios(this.user_info.access_token).get(
          this.API_SERVER + `/farm/info/?` + (page ? `&page=${this.page}` : "")
        );
        this.$debug("get project lists", response);
        if (response.status === 200 && response.data.results.length) {
          this.project_list = response.data.results;
          this.total = response.data.count;
        } else {
          this.$debug("error", response);
        }
      } catch (e) {
        this.$debug("error", e);
      } finally {
        this.$store.commit("loadingStatus", 0);
      }
    },
    // event after user delete file
    deleteFile: function (_file) {
      this.$debug("deleteParentFile", _file);

      // Delete new added pond
      this.pond_list = this.pond_list.filter(o=>o.id)
      this.$debug('this.pond_list', this.pond_list)

      this.file = "";
      this.pond = null;
    },
    // Post to the server
    async addScan() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        // focus error field
        for (let key in Object.keys(this.$v)) {
          const input = Object.keys(this.$v)[key];
          this.$debug(
            "error",
            input,
            this.$v,
            key,
            "/",
            this.$v[input],
            this.$v[input].$error
          );
          if (input.includes("$")) return false;

          if (this.$v[input].$error) {
            this.$debug( input, this.$refs, this.$refs[input] )
            this.$refs[input].focus();
            if (input === 0) this.$vuetify.goTo(0);
            break;
          }
        }
        return;
      }

      try {
        this.$debug( "this.pond", this.pond, this.temperature )
        if(!this.pond) {
          Swal.fire(
            `Please select pond`,
            "",
            "error"
          );
          return;
        }
        if(!this.sample_date) {
          Swal.fire(
            `Please select sample date`,
            "",
            "error"
          );
          return;
        }

        var formData = new FormData();
        formData.append("pond", this.pond);
        formData.append("sample_date", this.sample_date);
        // formData.append("aerator", this.aerator ? 1 : 0);
        // formData.append("feeder", this.feeder ? 1 : 0);
        // formData.append("maintenance", this.maintenance ? 1 : 0);
        this.$debug( "this.temperature.....", this.temperature )
        formData.append("size", this.size ? this.size : '');
        formData.append("temperature", this.temperature ? this.temperature : '');
        formData.append("oxygen", this.oxygen ? this.oxygen : '');
        formData.append("salinity", this.salinity ? this.salinity : '');
        formData.append("note", this.note);

        if(this.count) formData.append("count", this.count);
        if(this.biomass) formData.append("biomass", this.biomass);

        const _pond = this.pond_list.find(o => o.id===this.pond);

        // Insert
        if(!this.sample) {
          const result = await tokenAxios(this.user_info.access_token).post(
            process.env.VUE_APP_API_SERVER + `/farm/samples/`,
            formData
          );
          this.$debug( "result", result );
          Swal.fire(
          `${this.sample_date} : Pond ${_pond.pond_id} / ${_pond.date} is added`,
          "",
          "success"
        );
        // Update
        } else {
          this.$debug( "formData.....", formData )
          await tokenAxios(this.user_info.access_token).patch(
            process.env.VUE_APP_API_SERVER + `/farm/samples/${this.sample.id}/`,
            formData
          );
          Swal.fire(
          `${this.sample_date} : Pond ${_pond.pond_id} / ${_pond.date} is updated`,
          "",
          "success"
        );
        }

        // set Submitted
        this.status = 0;
        // this.listPonds();
        this.$store.commit("pond", _pond);
        this.$router.push('/samples')
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
    async removeFile(item) {
      const result = await Swal.fire({
        title: item.project_name,
        text: "Are you sure you want to remove this project?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      });
      if (!result.isConfirmed) return;

      try {
        const response = await tokenAxios(this.user_info.access_token).post(
          this.API_SERVER + `/farm/delete/`,
          {
            project_id: item.project_id,
          }
        );
        if (response.status === 200) {
          this.$debug("result", response);
          Swal.fire("Deleted!", "Your project has been deleted.", "success");
          this.listProjects();
        }
      } catch (error) {
        this.$debug("error", error.response);
      }
    },
  },
}
</script>

<style>
.subtitle {
  height:80px;
  margin: 20px 0;
}
.desc_container {
  background: url('/bg3.jpg');
  background-size: cover;
  opacity: 1;
  min-height: 200px;
  border-radius: 10px;
}
.v-list .left_name {
  min-width: 180px;
  margin: auto;
}
.blue, .red {
  color: #fff;
  border-radius: 5px;
}
.calculate {
  background-color: #e8eff5;
}
.v-picker {
  width: 100%;
}
.v-picker__body {
  max-width: 100%;
}
.activeonly {
  font-size: 9px;
  color: red;
}
.pondlist_completed .v-input__slot {
  background-color: #edfeee!important;
}
</style>
<style scoped>
.avatar {
  width: 30px;
  margin-right: 15px;
  border-radius: 10px;
}
</style>
